'use client'

import { theme } from '@ui/style'

const Icon16CloseTrue16 = ({ color }: SVGComponentProps) => {
	const iconColor = color || theme.color.colBlack
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M2 2L14 14" stroke={iconColor} strokeWidth="1.8" />
			<path d="M2 14L14 2" stroke={iconColor} strokeWidth="1.8" />
		</svg>
	)
}

export default Icon16CloseTrue16
