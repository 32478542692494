import { keyframes } from '@emotion/react'
import { styled } from '@mui/joy/styles'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import UIText from '@components/ui/UIText'
import UIFlex from '@components/ui/UIFlex'
import UIIconButton from '@components/ui/UIIconButton'
import { Box } from '@mui/joy'

export const newsSlideUp = keyframes`
  from {
  }
  to {
    transform: translateY(-100%);
  }
`
export const NewsLayout = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	height: theme.pxToRem(44),
	backgroundColor: theme.color.colDarkBlue,
	[theme.breakpoints.up('md')]: {
		height: theme.pxToRem(48),
	},
}))
export const NewsContainer = styled(UIFlex)(({ theme }) => ({
	position: 'relative',
	paddingTop: theme.pxToRem(10),
	paddingBottom: theme.pxToRem(10),
	alignItems: 'flex-start',
	[theme.breakpoints.up('md')]: {
		paddingTop: theme.pxToRem(12),
		paddingBottom: theme.pxToRem(12),
	},
}))
export const NewsBox = styled(UIFlex)(({ theme }) => ({
	flexGrow: 1,
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(22),
	[theme.breakpoints.up('md')]: {
		width: `calc(100% - ${theme.pxToRem(86)})`,
		lineHeight: theme.pxToRem(24),
		'&> *:not(:first-child)': { marginLeft: theme.pxToRem(24) },
	},
}))

export const NewsText = styled(UIText)(({ theme }) => ({
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(24),
	color: theme.color.colWhite,
}))

export const NewsList = styled(List)(({ theme }) => ({
	display: 'flex',
	margin: 0,
	padding: 0,
	fontSize: 'inherit',
	lineHeight: 'inherit',
	maxHeight: '50vh',
	overflow: 'auto',
	marginLeft: theme.pxToRem(16),
	[theme.breakpoints.up('md')]: {
		marginLeft: theme.pxToRem(24),
	},
	'& > *:first-child': {
		paddingRight: theme.pxToRem(40),
	},
	'&.open > *:first-child': {
		paddingRight: theme.pxToRem(80),
	},
	'&> *:not(:first-child)': { marginTop: theme.pxToRem(12) },
}))
export const NewsItem = styled(ListItem)(({ theme }) => ({
	margin: 0,
	padding: 0,
	fontSize: 'inherit',
	lineHeight: 'inherit',
	minBlockSize: 'auto',
	'&.animate': {
		animation: `${newsSlideUp} 1s forwards`,
	},
}))

export const NewsActionBox = styled(UIFlex)(({ theme }) => ({
	position: 'absolute',
	right: 0,
	top: theme.pxToRem(11),
	flexDirection: 'row',
	alignItems: 'center',
	flexShrink: 0,
	'&> *:not(:first-child)': { marginLeft: theme.pxToRem(10) },
	[theme.breakpoints.up('md')]: {
		top: theme.pxToRem(13),
		'&> *:not(:first-child)': { marginLeft: theme.pxToRem(12) },
	},
}))

export const ActionButton = styled(UIIconButton)(({ theme }) => ({
	margin: theme.pxToRem(-7),
	padding: theme.pxToRem(10),
	[theme.breakpoints.up('md')]: {
		margin: theme.pxToRem(-8),
		padding: theme.pxToRem(12),
	},
	'&:hover': {
		backgroundColor: 'unset',
	},
	'&:active': {
		backgroundColor: 'unset',
	},
}))

export const NewsRollBox = styled(List)(({ theme }) => ({
	margin: 0,
	padding: `0 ${theme.pxToRem(40)} 0 0`,
	height: theme.pxToRem(24),
	fontSize: theme.pxToRem(14),
	lineHeight: theme.pxToRem(18),
	overflow: 'hidden',
	marginLeft: theme.pxToRem(16),
	[theme.breakpoints.up('md')]: {
		height: theme.pxToRem(24),
		lineHeight: theme.pxToRem(24),
		marginLeft: theme.pxToRem(24),
	},
	'&.expand': {
		padding: `0 ${theme.pxToRem(80)} 0 0`,
	},
}))

export const NewsKindCode = styled(UIText)(({ theme }) => ({
	minWidth: theme.pxToRem(37),
	fontSize: theme.pxToRem(12),
	color: theme.color.colWhite,
	borderRadius: theme.pxToRem(22),
	padding: `${theme.pxToRem(4)} ${theme.pxToRem(8)}`,
	marginRight: theme.pxToRem(8),
	backgroundColor: theme.color.colExclusiveRed,
	fontWeight: '700',
	lineHeight: theme.pxToRem(14),
}))
