import { Box } from '@mui/joy'
import pxToRem from '@utils/pxToRem'
import setAds from '@utils/setAds'
import { useLayoutEffect } from 'react'

const HomePcAd = () => {
	useLayoutEffect(() => {
		setAds({
			id: 'sLnHsJmnQEi0hBmSJUEXJw',
			inventory: 'jtbc_p/main_news/main_news@main_news_top_970x100?mlink=493',
		})
	}, [])
	return (
		<Box
			display={{ xs: 'none', md: 'block' }}
			id="sLnHsJmnQEi0hBmSJUEXJw_AD"
			sx={{
				'&>div': {
					display: 'flex!important',
				},
				'&>div>div': {
					fontSize: 0,
					'&>div': {
						width: '100%!important',
						maxWidth: {
							md: '720px',
							lg: '928px',
							xl: '1216px',
						},
						position: 'relative',
					},
					'&>div>div:first-child': {
						marginLeft: '0!important',
						right: 0,
					},
					'a>img': {
						margin: '0 auto',
					},
				},
			}}
		/>
	)
}
export default HomePcAd
