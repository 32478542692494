import zIndexes from '@ui/style/zIndexes.theme'
import { css } from '@emotion/react'

/**
 * @description normalize
 */
export const globalStyleCSSText = css`
	html,
	body,
	div,
	span,
	applet,
	object,
	iframe,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	pre,
	a,
	abbr,
	acronym,
	address,
	big,
	cite,
	code,
	del,
	dfn,
	em,
	img,
	ins,
	kbd,
	q,
	s,
	samp,
	small,
	strike,
	strong,
	sub,
	sup,
	tt,
	var,
	b,
	u,
	i,
	center,
	dl,
	dt,
	dd,
	ol,
	ul,
	li,
	fieldset,
	form,
	label,
	legend,
	table,
	caption,
	tbody,
	tfoot,
	thead,
	tr,
	th,
	td,
	article,
	aside,
	canvas,
	details,
	embed,
	figure,
	figcaption,
	footer,
	header,
	hgroup,
	menu,
	nav,
	output,
	ruby,
	section,
	summary,
	time,
	mark,
	audio,
	video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	menu,
	nav,
	section {
		display: block;
	}
	html,
	body {
		height: max(100%, 100vh);
	}
	body {
		line-height: 1;
		-webkit-touch-callout: none;
	}
	body.bgBlack {
		background: #242424;
	}
	@supports (-webkit-text-size-adjust: none) and (not (-ms-accelerator: true)) and
		(not (-moz-appearance: none)) {
		body {
			-webkit-text-size-adjust: 100%;
		}
	}
	body.scroll-lock-all {
		overflow: hidden !important;
		touch-action: none;
		-webkit-overflow-scrolling: none;
		overscroll-behavior: none;
	}
	@media only screen and (max-width: 1024px) {
		body.scroll-lock-under-lg {
			overflow: hidden !important;
			touch-action: none;
			-webkit-overflow-scrolling: none;
			overscroll-behavior: none;
		}
	}
	@media only screen and (max-width: 768px) {
		body.scroll-lock-under-md {
			overflow: hidden !important;
			touch-action: none;
			-webkit-overflow-scrolling: none;
			overscroll-behavior: none;
		}
	}
	@media only screen and (max-width: 1024px) {
		body.live-talk-scroll-lock {
			overflow: hidden !important;
			touch-action: none;
			-webkit-overflow-scrolling: none;
			overscroll-behavior: none;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
		}
	}
	@media only screen and (max-width: 768px) {
		body.journalist-talk-scroll-lock {
			overflow: hidden !important;
			touch-action: none;
			-webkit-overflow-scrolling: none;
			overscroll-behavior: none;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
		}
	}
	ol,
	ul,
	li {
		list-style: none;
	}
	blockquote,
	q {
		quotes: none;
	}
	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	html {
		line-height: 1.5;
		font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
			Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR',
			'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
		color: #242424;
		min-width: 393px;
		font-feature-settings: 'case' on;
	}
	body {
		line-height: inherit;
	}
	a {
		color: inherit;
		text-decoration: inherit;
	}
	* {
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
	}
	.a11y {
		overflow: hidden;
		position: absolute;
		clip: rect(0, 0, 0, 0);
		clip-path: circle(0);
		width: 1px;
		height: 1px;
		margin: -1px;
		border: 0;
		padding: 0;
		white-space: nowrap;
	}
	button {
		display: block;
		font-family: inherit;
		background: transparent;
		border: 0;
		border-radius: 0;
		padding: 0;
		color: inherit;
		cursor: pointer;
	}
	img {
		display: block;
	}
	svg {
		display: block;
	}
	/* 임시로 mui와 동일한 색상으로 둠 */
	*:focus-visible {
		outline-color: #000;
		outline-offset: -2px;
	}
	/* tanstack 버튼 숨김 */
	.tsqd-open-btn-container {
		/* display: none; */
	}
	/* 인쇄 영역 bg 보이기 */
	* {
		-webkit-print-color-adjust: exact !important;
		color-adjust: exact !important;
		print-color-adjust: exact !important;
	}

	/* counter reset.css */
	html {
		background: transparent;
	}
	body {
		letter-spacing: 0;
	}

	/* notistack z-index */
	.notistack-SnackbarContainer {
		z-index: ${zIndexes.toast};
	}
	.notistack-SnackbarContainer > div {
		pointer-events: none !important;
	}
`

/**
 * @description object type
 */
export const demoStyle = css`
	body {
		padding-bottom: 9rem;
		background-color: rgb(10, 10, 11);
		overflow-y: scroll;
		margin: 0;
		line-height: inherit;
		color: #ffffff;
	}

	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border-width: 0;
	}
`
