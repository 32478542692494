import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

type VideoResolutionState = {
	resolution: VodResolution
}

export const videoResolutionState = atom<VideoResolutionState>({
	key: '#videoResolutionState',
	default: {
		resolution: '720',
	},
})

export const useVideoResolution = () => {
	const [state, setState] = useRecoilState(videoResolutionState)

	const setVideoResolution = useCallback(
		(value: VodResolution) => {
			if (value) {
				setState({ resolution: value })
			}
		},
		[setState],
	)

	return { videoResolution: state.resolution, setVideoResolution }
}
