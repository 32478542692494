'use client'

import { Box, ModalDialog as JoyModalDialog, styled } from '@mui/joy'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import UISimpleModal from '@components/ui/UISimpleModal'
import UIButton from '@components/ui/UIButton'
import { useConfirmControl } from '@store/confirm'
import { uuidv4 } from '@utils/random'
import UIDivider from '@components/ui/UIDivider'
import UIText from '@components/ui/UIText'
import { Fragment } from 'react'
import useIsMounted from '@hooks/useIsMounted'
import { theme } from '@ui/style'

type TButton = {
	text: string
	onClick?: (onClose: () => void) => void
}
export type TModal = {
	id?: string
	open?: boolean
	title?: string
	message: string | React.ReactNode
	buttons: TButton[]
}

const ModalDialog = styled(JoyModalDialog)`
	background-color: ${color.colWhite};
	border-radius: 8px;
	box-shadow: 0 4px 16px 0 rgba(36, 36, 36, 0.15);
	padding: 0;
	gap: 0;
	min-width: 0;
	border: 0;
	width: ${pxToRem(288)};

	${theme.breakpoints.up('md')} {
		width: ${pxToRem(260)};
	}
`

const MessageBox = styled(Box)(
	({ title }) => `
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: ${pxToRem(title ? 20 : 24)} ${pxToRem(23)};
	& > *:not(:first-child) {
		margin-top: ${pxToRem(8)}
	}
	text-align: center;
	white-space: pre-line;
	${theme.breakpoints.up('md')} {
		padding: ${pxToRem(title ? 20 : 24)} ${pxToRem(32)};
	}
`,
)

const Button = styled(UIButton)`
	color: ${color.colBlack};
	font-size: ${pxToRem(14)};
	font-weight: 600;
	line-height: ${pxToRem(16)};
	padding: ${pxToRem(16)} 0 !important;
	&:hover,
	&:active {
		color: ${color.colBlack};
	}
`

const ConfirmModal = () => {
	const { confirm, closeConfirm } = useConfirmControl()
	const isMount = useIsMounted()
	return (
		isMount &&
		confirm &&
		confirm.map((modal) => {
			const { id, title, message, buttons } = modal
			const handleClose = () => {
				closeConfirm(id)
			}
			return (
				<UISimpleModal open key={`confirm-${id}`}>
					<ModalDialog>
						<MessageBox>
							{title && (
								<UIText
									fontSize={pxToRem(14)}
									lineHeight={pxToRem(20)}
									fontWeight={700}
								>
									{title}
								</UIText>
							)}
							<UIText
								fontSize={pxToRem(13)}
								lineHeight={pxToRem(20)}
								color={color.colGray5}
								fontWeight={500}
							>
								{message}
							</UIText>
						</MessageBox>
						<UIDivider orientation="horizontal" />
						<Box display="flex" alignItems="center">
							{buttons?.map(({ text, onClick }) => (
								<Fragment key={`button-${uuidv4()}`}>
									<Button
										variant="text"
										onClick={() => {
											if (onClick) {
												onClick(handleClose)
											} else {
												handleClose()
											}
										}}
										sx={{ width: `calc(100% / ${buttons.length})` }}
									>
										{text}
									</Button>
									<UIDivider sx={{ '&:last-of-type': { display: 'none' } }} />
								</Fragment>
							))}
						</Box>
					</ModalDialog>
				</UISimpleModal>
			)
		})
	)
}

export default ConfirmModal
