'use client'

import SSRSuspense from '@components/layout/SSRSuspense'
import UIContainer from '@components/ui/UIContainer'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useLocalStorage } from '@hooks/useLocalStorage'
import Box from '@mui/joy/Box'
import { ApiResultVoListArticleListResponseVo, ArticleListResponseVo } from '@schemas/non-auth'
import { useGnbNewsflash, useGnbNewsflashMutation } from '@services/api/Article/GnbNewsflash'
import { theme } from '@ui/style'
import color from '@ui/style/color.theme'
import { usePathname } from 'next/navigation'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Icon16ArrowLarge } from 'src/assets/icons/Icon16ArrowLarge'
import { Icon16Close } from 'src/assets/icons/Icon16Close'
import {
	ActionButton,
	NewsActionBox,
	NewsBox,
	NewsContainer,
	NewsItem,
	NewsLayout,
	NewsList,
	NewsRollBox,
	NewsText,
	NewsKindCode,
} from './styled'
import pxToRem from '@utils/pxToRem'

const BreakingNews = () => {
	const pathname = usePathname()
	const isShorts = pathname.includes('/shorts')
	const [open, setOpen] = useState<boolean>(false)
	const [prevIndex, setPrevIndex] = useState<number>(0)
	const [currentIndex, setCurrentIndex] = useState(0)
	const [breaked, setBreaked] = useState<boolean>(false)
	const [newsData, setNewsData] = useState<Array<ArticleListResponseVo>>([])
	const [oldData, setOldData] = useState<Array<ArticleListResponseVo>>()
	const [prevPage, setPrevPage] = useState<string>()
	const { data: newsflash } = useGnbNewsflash()
	const { mutateAsync: newsflashMutate } = useGnbNewsflashMutation()

	const [userNewsflashSettings, setUserNewsflashSettings] = useLocalStorage(
		'userNewsflashSettings',
		{
			isOpen: true,
			latestIdx: '',
		},
	)

	const handleToggle = () => {
		setOpen(!open)
	}
	const handleClose = useCallback(() => {
		setOldData(newsData)
		setNewsData([])
		window.dispatchEvent(new Event('resize'))
		setUserNewsflashSettings({
			isOpen: false,
			latestIdx: newsData[0]?.articleIdx, // 가장 첫번째 데이터가 마지막으로 등록된 데이터
		})
	}, [newsData])
	const slideUp = useCallback(() => {
		if ((newsData?.length ?? 0) > 0) {
			if (!open && !breaked) {
				if (prevIndex !== currentIndex) {
					setPrevIndex(() => (prevIndex + 1) % newsData.length)
				}
				setCurrentIndex((currentIndex + 1) % (newsData?.length ?? 1))
			}
		}
	}, [prevIndex, currentIndex, open, breaked, newsData])
	const handleBlur = () => {
		slideUp()
		setBreaked(false)
	}
	const fetchGnbNewsflash = async () => {
		const { data }: ApiResultVoListArticleListResponseVo = await newsflashMutate()
		if (
			data &&
			data?.length > 0 &&
			(!oldData || oldData.length === 0 || JSON.stringify(oldData) !== JSON.stringify(data))
		) {
			setNewsData(data)
			setPrevIndex(0)
			setCurrentIndex(0)
		}
	}
	useEffect(() => {
		if ((newsflash?.length ?? 0) > 0) {
			if (
				!oldData ||
				oldData.length === 0 ||
				JSON.stringify(oldData) !== JSON.stringify(newsflash)
			) {
				setNewsData(newsflash)
				setPrevIndex(0)
				setCurrentIndex(0)
			}
		}
		window.dispatchEvent(new Event('resize'))
	}, [newsflash])
	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout> | undefined
		let intervalId: ReturnType<typeof setInterval> | undefined

		if (newsData.length > 1) {
			timeoutId = setTimeout(() => {
				intervalId = setInterval(() => {
					slideUp()
				}, 2000)
			}, 1000)
		}

		return () => {
			clearTimeout(timeoutId) // setTimeout 클린업
			clearInterval(intervalId) // setInterval 클린업
		}
	}, [slideUp, newsData.length])
	useEffect(() => {
		setOpen(false)
		setPrevPage(pathname)
		if (prevPage && prevPage !== pathname) {
			fetchGnbNewsflash()
		}
	}, [pathname, prevPage])

	const showNewsflash = useMemo(() => {
		return (
			newsData.length > 0 &&
			(userNewsflashSettings.isOpen ||
				userNewsflashSettings.latestIdx !== newsData[0]?.articleIdx)
		)
	}, [newsData, userNewsflashSettings.isOpen, userNewsflashSettings.latestIdx])

	const removeBreakingNewsPrefix = (title: string) => {
		return title?.replace(/\s*\[속보\]\s*/g, '')
	}

	return (
		showNewsflash && (
			<NewsLayout
				className="breaking-news"
				sx={{
					position: isShorts ? 'absolute' : 'relative',
					top: isShorts ? '100%' : 0,
					left: 0,
				}}
			>
				<Box width="100%" position="absolute" bgcolor={color.colDarkBlue}>
					<UIContainer>
						<NewsContainer direction="row" justify="space-between">
							<NewsBox direction="row">
								<NewsText
									weight={700}
									sx={{ minWidth: pxToRem(27), lineHeight: pxToRem(24) }}
								>
									속보
								</NewsText>
								<SSRSuspense fallback={null}>
									{open ? (
										<NewsList className={open ? 'open' : ''}>
											{newsData?.map((item) => (
												<NewsItem key={`article-${item.articleIdx}`}>
													<NewsText
														lineClamp={1}
														{...{
															component: UILink,
															href: `/article/${item.articleIdx}`,
														}}
													>
														{item.breakingnewKindCode ===
															'CALAMITY' && (
															<NewsKindCode>긴급</NewsKindCode>
														)}
														{removeBreakingNewsPrefix(
															item.articleTitle,
														)}
													</NewsText>
												</NewsItem>
											))}
										</NewsList>
									) : (
										<NewsRollBox
											className={newsData?.length > 1 ? 'expand' : ''}
										>
											{prevIndex === currentIndex ? (
												<NewsItem
													key={`article-single-${newsData[currentIndex]?.articleIdx}`}
													className={newsData?.length > 1 ? 'test2' : ''}
													onFocus={() => setBreaked(true)}
													onBlur={handleBlur}
												>
													<NewsText
														lineClamp={1}
														{...{
															component: UILink,
															href: `/article/${newsData[currentIndex]?.articleIdx}`,
														}}
													>
														{newsData[currentIndex]
															?.breakingnewKindCode ===
															'CALAMITY' && (
															<NewsKindCode>긴급</NewsKindCode>
														)}
														{removeBreakingNewsPrefix(
															newsData[currentIndex]?.articleTitle ??
																'',
														)}
													</NewsText>
												</NewsItem>
											) : (
												<>
													<NewsItem
														className={
															prevIndex !== currentIndex
																? 'animate'
																: ''
														}
														key={`article-previous-${newsData[prevIndex]?.articleIdx}`}
													>
														<NewsText lineClamp={1} tabIndex={-1}>
															{newsData[prevIndex]
																?.breakingnewKindCode ===
																'CALAMITY' && (
																<NewsKindCode>긴급</NewsKindCode>
															)}
															{removeBreakingNewsPrefix(
																newsData[prevIndex]?.articleTitle ??
																	'',
															)}
														</NewsText>
													</NewsItem>
													<NewsItem
														className="animate"
														key={`article-current-${newsData[currentIndex]?.articleIdx}`}
														onFocus={() => setBreaked(true)}
														onBlur={handleBlur}
													>
														<NewsText
															lineClamp={1}
															{...{
																component: UILink,
																href: `/article/${newsData[currentIndex]?.articleIdx}`,
															}}
														>
															{newsData[currentIndex]
																?.breakingnewKindCode ===
																'CALAMITY' && (
																<NewsKindCode>긴급</NewsKindCode>
															)}
															{removeBreakingNewsPrefix(
																newsData[currentIndex]
																	?.articleTitle ?? '',
															)}
														</NewsText>
													</NewsItem>
												</>
											)}
										</NewsRollBox>
									)}
								</SSRSuspense>
							</NewsBox>
							<NewsActionBox>
								{newsData?.length > 1 && (
									<SSRSuspense fallback={null}>
										<ActionButton onClick={handleToggle}>
											<UIText readonly>속보 목록 열기</UIText>
											<Icon16ArrowLarge
												direction={open ? 'up' : 'down'}
												color={theme.color.colWhite}
											/>
										</ActionButton>
									</SSRSuspense>
								)}
								<ActionButton onClick={handleClose}>
									<UIText readonly>속보 닫기</UIText>
									<Icon16Close color={theme.color.colWhite} />
								</ActionButton>
							</NewsActionBox>
						</NewsContainer>
					</UIContainer>
				</Box>
			</NewsLayout>
		)
	)
}

export default BreakingNews
