import { Box } from '@mui/joy'
import setAds from '@utils/setAds'
import { useLayoutEffect } from 'react'

const HomeMoAd = () => {
	useLayoutEffect(() => {
		setAds({
			id: 'Grb7pnQuRbq3XehnNJB7_Q',
			inventory: 'jtbc_m/main_news/main_news@main_news_top_320x50?mlink=497',
		})
	}, [])
	return (
		<Box
			display={{ xs: 'block', md: 'none' }}
			id="Grb7pnQuRbq3XehnNJB7_Q_AD"
			sx={{
				'&>div': {
					display: 'flex!important',
					justifyContent: 'center',
				},
			}}
		/>
	)
}
export default HomeMoAd
